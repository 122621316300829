.img-car{
    height: 420px;
    object-fit: cover;
}
#car{
    height: 420px;
    
    border-radius: 10px;
    box-shadow: 15px 15px #ee984a;
    border: 1px solid;
   
}
.carosaul-div{
    margin-top: 2%;
    width: 100%;
    padding:1%;
}


.left {
    float: left;
    max-width: 100%;
    width: 70%;
    padding: 10px;
    height: 300px; /* Should be removed. Only for demonstration */
  }

.right {
    float: left;
    width: 30%;
    padding: 10px;
    height: 300px; /* Should be removed. Only for demonstration */
  }

  
  /* Clear floats after the columns */
  .row:after {
    margin-top: 5%;
    content: "";
    display: table;
  }

 
  .right-inner{
 
  }
